@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,700);
html,
body {
padding: 0;
margin: 0;
}

* {
box-sizing: border-box;
}

ul, ol {
padding: 0;
margin: 0;
}

li {
padding: 0;
margin: 0 0 0 1rem;
}

a {
text-decoration: none;
}

ul ul {
margin: 0 0 0 1em;
}

ul ul ul {
margin: 0 0 0 2em;
}

body {
background-color: #fff;
color: #3e3e3e;
}



.colorBgGrey {
background-color: #f6f6f6;
}

.colorBgGreyLight {
background-color: #fafafa;
}

.colorBgGreyPlus {
background-color: #dddddd;
}

.hcTxtColorGreyMid {
color: #999999;
}

body {
font-family: 'Roboto', sans-serif;
font-weight: 300;
font-size: 16px;
line-height: 140%;
font-style: normal;
color: #3e3e3e;
}

h1 {
font-size: 1.5em;
line-height: 130%;
font-weight: 700;
color: rgb(73, 85, 114);
}

h2 {
font-size: 1.3em;
line-height: 130%;
font-weight: 700;
color: rgb(73, 85, 114);
}

h3 {
font-size: 1em;
line-height: 130%;
font-weight: 700;
}

h4 {
font-size: .85em;
line-height: 130%;
font-weight: 300;
font-style: italic;
}

@media (min-width: 600px) {
body {
font-size: 16px;
line-height: 150%;
}

h1 {
font-size: 2em;
}

h1, h2, h3, h4 {
line-height: 150%;
}
}

h1, h2, h3, h4 {
margin-top: 0;
margin-bottom: .3em;
}

p {
margin-top: 0;
margin-bottom: 1em;
}

strong {
font-weight: 700;
}

.hcSmallTxt {
font-size: .85rem;
}

.hcMonospace {
font-family: monospace;
}

.icon {
width: 1.3rem !important;
height: auto !important;
}

.hcIconText {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
}

.hcIconText .icon {
margin-right: .5rem;
}

.hcIcon svg {
display: block;
width: 2rem;
height: auto;
}

.hcIconFill {
fill: #3e3e3e;
}

.hcIconStroke {
stroke: #3e3e3e;
stroke-width: 3px;
}

.hcIconSmall svg {
width: 1rem;
}



.hcBasicPading {
padding: 1rem;
}

.hcMarginBottom1 {
margin-bottom: 1rem;
}

.hcMarginBottom2 {
margin-bottom: 2rem;
}

.hcMarginBottom3 {
margin-bottom: 3rem;
}

.hcMarginBottom5 {
margin-bottom: 5rem;
}

.hcMarginBottom15 {
margin-bottom: 15rem;
}

.hcMarginTop1 {
margin-top: 1rem;
}

.hcMarginTop2 {
margin-top: 2rem;
}

.hcMarginTop3 {
margin-top: 3rem;
}

.hcMarginTop5 {
margin-top: 5rem;
}

.hcMarginBackground {
padding-top: 4rem;
padding-bottom: 4rem;
}

.hcNestedItemMargin1 > * {
margin-right: 1rem;
margin-bottom: 1rem;
}

.hcAlignHorizontal {
display: flex;
flex-direction: row;
}

.hcAlignVertical {
display: flex;
flex-direction: column;
}

.hcAlignLeft {
display: flex;
justify-content: flex-start;
}

.hcAlignRight {
display: flex;
justify-content: flex-end;
}

.hcAlignVertical.alignRight {
display: flex;
align-items: flex-end;
}

.hcAlignCenter {
display: flex;
justify-content: center;
}

.hcAlignVertical.alignCenter {
display: flex;
align-items: center;
}

.hcRowLeft {
display: flex;
justify-content: flex-start;
align-items: center;
}

.hcRowRight {
display: flex;
justify-content: flex-end;
align-items: center;
}

.hcRowJustify {
display: flex;
justify-content: space-between;
align-items: center;
}

.hcList {
display: flex;
flex-direction: column;
}

.hcList li {
margin: .5rem 0 .5rem 0;
}

.hcList > * {
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcList > *:nth-child(odd) {
background-color: whitesmoke;
margin: 0 -1rem;
padding: 1rem;
}

.hcList > *:hover {
background-color: #e8e8e8;
}

.hcList > * > * {
flex: 1 1;
margin-right: 1rem;
}

@media (min-width: 600px) {
.hcList > * {
flex-direction: row;
display: flex;
}
}

.hcListItemLong {
flex: 2 1;
}

.hcListHeader {
background: none;
}

.hcListBasicResult {
border-bottom: 1px solid rgba(0, 0, 0, 0.05);
padding: .7rem 0;
cursor: pointer;
}

.hcRowCard {
background-color: #fff;
padding: 1rem;
margin: 0 -1rem .5rem -1rem;
border-radius: 2px;
}

.hcRowCard > * {
display: flex;
flex-direction: column;
justify-content: flex-start;
}

.hcListItemBlock {
padding: 0;
transition: background-color .3s;
margin-bottom: 1.5rem;
}

.hcListItemBlock > * {
padding: 1rem 2rem;
}

.hcListItemDPPBlock {
flex: 2 1;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcKwic {
border-bottom: 3px dotted #e7da67;
}

.hcListItemThumb {
overflow-y: scroll;
display: flex;
align-items: flex-start;
justify-content: center;
background-color: #3b3b3b;
padding-top: .5rem;
}

.hcListItemThumb div {
height: 170px;
}

.hcListItemThumb img {
width: 200px !important;
height: auto !important;
}

.hcResultsHeader {
display: flex;
justify-content: space-between;
margin-top: 40px;
}

.hcMessageBar {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
font-size: .85rem;
line-height: 140%;
font-style: italic;
background-color: whitesmoke;
border-radius: 4px;
}

.hcMessageBar > * {
padding: .5rem;
}

.notificationInform {
color: #005466;
background-color: #ccf6ff;
}

.notificationInform .hcIconFill {
fill: #005466;
}

.notificationInform .hcIconStroke {
stroke: #005466;
}

.notificationCaution {
color: #967d1c;
background-color: white;
}

.notificationCaution .hcIconFill {
fill: #967d1c;
}

.notificationCaution .hcIconStroke {
stroke: #967d1c;
}

.notificationError {
color: #3b0b00;
background-color: #ffb2a1;
}

.notificationError .hcIconFill {
fill: #3b0b00;
}

.notificationError .hcIconStroke {
stroke: #3b0b00;
}

.notificationReward {
color: #476018;
background-color: #f1f8e5;
}

.notificationReward .hcIconFill {
fill: #476018;
}

.notificationReward .hcIconStroke {
stroke: #476018;
}


.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns > * {
flex-basis: 50%;
}

@media (min-width: 800px) {
.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
flex-direction: row;
}

.hc2columns > * {
flex-basis: 50%;
}

.hc3columns > * {
flex-basis: 33%;
}

.hc4columns > * {
flex-basis: 25%;
}

.hc5columns > * {
flex-basis: 20%;
}

.hc6columns > * {
flex-basis: 15%;
}
}

button {
font-size: .7rem;
padding: .5rem;
border-radius: 4px;
background-color: rgba(0, 0, 0, 0.07);
border: 1px solid rgba(0, 0, 0, 0.09);
cursor: pointer;
font-weight: 700;
}

.hc-loud {
padding: .8rem;
font-size: 1rem;
background-color: #9ed041;
}

.hc-small {
padding: .5rem;
font-size: .8rem;
}

.hc-subtle {
background: none;
border: none;
box-shadow: none;
color: #0087d4;
}

.hcfixedSideButton {
width: 2rem;
padding: .2rem;
border-radius: 0 4px 4px 0;
}

input, select, option, textarea {
width: 80%;
padding: .5rem;
border: 1px solid #999999;
border-radius: 4px;
font-size: 0.85rem;
font-weight: 300;
color: #3e3e3e;
height: 30px;
}

.hcForm input, .hcForm select, .hcForm option, .hcForm textarea {
background-color: #f0f5f9;
max-width: 480px;
background-color: #f0f5f9;
}

.hcForm textarea {
height: auto;
min-height: 150px;
}

.hcLabel {
color: #999999;
text-transform: uppercase;
font-size: .85rem;
}

.hcStickyFooter {
position: fixed;
width: 100%;
min-width: 40px;
bottom: 0;
}

.hcTextSnippit::before {
content: " ...";
}

.hcTextSnippit::after {
content: "... ";
}

.hcDatasetEntity {
font-size: .85rem;
padding: .0rem 0 .0rem .5rem;
border: 1px solid #e7e7e7;
border-radius: .5rem 0 0 .5rem;
margin: 0 .5rem .5rem 0;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.hcDatasetEntityAmount {
border-radius: 3px;
padding: 0 .5rem;
margin: -1px -2px -1px 0;
background-color: #e7e7e7;
color: #3e3e3e;
min-width: 3.5rem;
text-align: right;
}

.hcPartnerLogo img {
width: 130px !important;

}

.hcPartnerLogo {
display: table-cell;
width: 23%;
text-align: center;
vertical-align: middle;
cursor: pointer;
}

.hcPartnerLogos {
background-color: #ffffff;
display: table;
width: 100%;
}

.hcPartnerLogoRow {
width: 100%;
display: table-row;
}

.hcDensity {
width: 100%;
min-width: 100px;
background-color: #e3e3e3;
}

.hcDensityBar {
background-color: #3aa5b0;
font-size: .5rem;
color: #fff;
padding: 0 0 0 .5rem;
}

.hcUriType {
font-family: monospace;
font-size: .85rem;
}

.hcBreakAll {
overflow-wrap: break-word;
word-break: break-all;
-webkit-hyphens: auto;
    -ms-hyphens: auto;
        hyphens: auto;
}

.hcBarDataset {
display: flex;
flex-direction: column;
padding-bottom: 1rem;
}

@media (min-width: 600px) {
.hcBarDataset {
flex-direction: row;
}

select {
font-size: 14px !important;
}
}

.hcBorderBottom {
border-bottom: 1px solid #ededed;
}

.hcMapPlaceholder {
width: 100%;
height: 250px;
/*background-image: url("../images/map-placeholder.jpg");*/
background-size: cover;
background-position: center;
}

hr {
border: none;
height: 1px;
color: rgba(0, 0, 0, 0.15);
/* old IE */
background-color: rgba(0, 0, 0, 0.15);
/* Modern Browsers */
}

.hcContentContainer {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
}

.hcContentContainer > * {
width: 100%;
max-width: 1200px;
}

.hcContentContainer > * img {
width: 100%;
height: auto;
}

.hcBlockText {
width: 100%;
}

.hcBlockText ol, .hcBlockText ul {
margin-left: 1rem;
}

.hcBlockText > * {
width: 100%;
}

@media (min-width: 800px) {
.hcBlockText > * {
max-width: 70%;
}
}

.hcHeadSpace {
height: 25vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}

@media (min-width: 800px) {
.hcHeadSpace {
flex-direction: row;
align-items: center;
justify-content: space-between;
}
}

.hcBlockScroll {
height: 100vh;
overflow-y: auto;
}

.hcBlockHeroWithTeaser {
height: 66vh;
display: flex;
justify-content: center;
}

.hcLayoutBasicMainAside, .hcLayoutBasicMainFixedAside {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.hcLayoutAside {
width: 100%;
}

.hcLayoutMain {
width: 100%;
}

@media (min-width: 800px) {
.hcLayoutBasicMainAside {
flex-direction: row;
}

.hcLayoutAside {
width: 300px;
}

.hcLayoutMain {
width: calc(100vw - 300px);
min-width: calc(800px - 300px);
}
}

@media (min-width: 800px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end;
}

.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 300px;
position: fixed !important;
top: 0;
left: 0;
bottom: 0;
}

.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 300px);
margin-left: 300px;
}

.hcLayoutBasicMainFixedAside .asideWithProductBar {
display: flex;
}

.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
}

.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 300px);
}
}

@media (min-width: 1300px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end;
}

.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 400px;
}

.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 400px);
margin-left: 400px;
}

.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
width: 100%;
}

.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 400px);
}
}

.hcLayoutFacet-Result {
display: flex;
flex-direction: column;
align-items: flex-start;
}

.hcLayoutFacets {
width: 100%;
}

.hcLayoutResults {
width: 100%;
}

.hcLayoutFacetsToggle {
display: flex;
height: 230px;
flex-direction: row;
overflow-x: auto;
background-color: #fafafa;
}

.hcLayoutFacetsToggle div {
width: 250px;
margin-right: 3rem;
}

#showFacets {
position: fixed;
top: 45vh;
left: 0;
}

@media (min-width: 800px) {
.hcLayoutFacet-Result {
flex-direction: row;
}

.hcLayoutFacets {
width: 350px;
}

.hcLayoutResults {
width: 100%;
/*width: calc(100% - 350px); */
}

.hcLayoutFacetsToggle {
display: block;
overflow-x: none;
height: auto;
flex-direction: column;
background: none;
}

.hcLayoutFacetsToggle div {
width: 100%;
margin-right: auto;
}

#showFacets {
display: none;
position: fixed;
}
}

.hcThumbsRow {
display: flex;
flex-direction: row;
}

.hcThumbsRow img {
margin: 1rem 0 1rem 1rem;
cursor: pointer;
}

.hclayoutTextImageDivider {
display: flex;
flex-direction: row;
height: calc(100vh - 150px);
}

.hcViewer {
width: 45%;
}

.hcMeta {
max-width: 250px;
}

.hcText {
display: flex;
flex-direction: row;
justify-content: center;
}

.hcText > * {
max-width: 550px;
margin-bottom: 500px;
}

.hcLayout-right-sidebar {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
}

.hcLayout-right-sidebar-main {
width: 100%;
}

.hcLayout-right-sidebar-aside {
width: 100%;
}

@media (min-width: 800px) {
.hcLayout-right-sidebar {
flex-direction: row;
}

.hcLayout-right-sidebar-main {
width: calc(100% - 250px - 2rem);
}

.hcLayout-right-sidebar-aside {
width: 250px;
}
}

.hcSidebar div {
margin-bottom: 1.5rem;
}

.navigation a {
color: #3e3e3e;
font-weight: 700;
font-size: 14px;
padding: .2rem;
margin-right: .5rem;
}

.navigation .navigation-sub > * {
font-weight: 300;
}

.navigation li {
list-style: none;
}

.navigation .navigation-secundairy-items {
display: none;
}

.hcSiteTitle {
color: #eee;
text-decoration: none;
text-transform: uppercase;
font-weight: bold;
font-size: 20px;
margin: 6px 0 0 16px;
}

.hcPageHeaderSimple {
display: flex;
flex-direction: column;
justify-content: space-between;
font-size: .9rem;
width: 100%;
}

.hcPageHeaderSimple nav {
display: flex;
flex-direction: row;
height: 100%;
align-items: baseline;
line-height: 130%;
}

.hcPageHeaderSimple nav a, .hcPageHeaderSimple nav div {
height: 100%;
padding: 1.5rem 1rem;
display: flex;
color: #fff;
align-items: center;
cursor: pointer;
}

.hcPageHeaderSimple nav a:hover {
background-color: rgba(255, 255, 255, 0.11);
}

.hcPageHeaderSimple nav a:first-child {
margin-left: -1rem;
}

.hcPageHeaderSimple .hcMenuButton {
border: none;
background: none;
font-size: .9rem;
color: #fff;
display: flex;
flex-direction: row;
align-items: center;
margin-left: 2rem;
}

.hcPageHeaderSimple .hcMenuButton img {
width: .7rem;
height: .7rem;
margin-left: .5rem;
}

.hcPageHeaderSimple > * {
height: 100%;
display: flex;
align-items: center;
}

.hcSecundairNav {
display: none;
}

@media (min-width: 600px) {
.hcPageHeaderSimple {
flex-direction: row;
}

.hcSecundairNav {
display: flex;
}

.hcMenuButton {
display: none;
}
}

.hcBrand {
display: flex;
flex-direction: row;
justify-content: flex-start;
height: 100%;
}

.hcBrandLogo img {
margin-top: 6px;
height: 33px;
width: auto;
}

.hcBrandName {
padding: 1.5rem 1rem 1rem 1rem;
font-weight: 700;
}

@media (min-width: 600px) {
.hcBrandLogo img {
height: 60px;
width: auto;
}
}

.hcFullscreenMenu {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
font-size: 1.5rem !important;
align-items: flex-start;
justify-content: flex-start;
}

.hcFullscreenMenu nav {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
min-height: 50vh;
}

.hcFullscreenMenu nav a {
padding: 0 0rem;
}

.hcFullscreenMenu #hcMenuButton {
position: fixed;
top: 30px;
right: 30px;
}

.hcFullscreenMenu .hcSecundairNav {
display: flex;
}

.hcFullscreenMenu nav a:first-child {
margin-left: 0rem;
}

.hcFullscreenMenu .hcBrand {
margin-left: 0rem;
margin-top: 6rem;
margin-bottom: 3rem;
}

.hcFullscreenMenu .hcPageHeaderSimple {
flex-direction: column;
}

.hcTabs {
display: flex;
width: 100%;
}

.hcTabs .hcSelectedTab {
font-weight: 700;
}

.hcTabsVert {
flex-direction: column;
}

.hcTabsVert .hcTabLabels {
display: flex;
flex-direction: column;
}

.hcTabsHoriz {
flex-direction: column;
}

.hcTabsHoriz .hcTabLabels {
display: flex;
flex-direction: row;
}

.hcTabLabels {
width: 100%;
}

.hcTabAllContent {
width: 100%;
}

@media (min-width: 500px) {
.hcTabsVert {
flex-direction: row;
}

.hcTabLabels {
width: 20%;
}

.hcTabAllContent {
width: 80%;
}
}

.hcTabContent {
width: 100%;
}

.hcTabLabel {
cursor: pointer;
padding: .5rem;
}

.hcFormItem {
display: flex;
flex-direction: column;
width: 100%;
}

.hcFormItem > * {
width: 100%;
}

.hcFeedbackError {
border-bottom: 3px solid #d42600 !important;
}

.hcFeedbackSucces {
border-bottom: 3px solid #d42600 !important;
}

.hcFormItemRow {
display: flex;
flex-direction: column;
justify-content: space-between;
}

.hcFormItemExtra {
width: 100%;
}

@media (min-width: 1280px) {
.hcFormItemRow {
flex-direction: row;
}

.hcFormItemExtra {
width: 60%;
display: flex;
align-items: center;
padding-left: 1rem;
}
}

.hcProvenanceComp {
width: 100%;
display: flex;
flex-direction: column;
display: none;
}

.hcProvenanceComp textarea {
height: 30px !important;
}

.hcFormStack {
display: flex;
flex-direction: column;
border-top: 1px solid #e8e8e8;
padding-top: 2rem;
width: 100%;
}

.hcFormStack > * {
width: 100%;
display: flex;
flex-direction: column;
}

.hcStackInfo {
margin-bottom: 2rem;
margin-right: 2rem;
}

@media (min-width: 800px) {
.hcFormStack {
flex-direction: row;
}

.hcStackInfo {
width: calc(30% - 2rem);
}

.hcStackFormItems {
width: calc(70%);
padding-top: 1.5rem;
}
}

.hcProductBar {
width: 100vw;
padding: 0 1.5rem;
display: flex;
justify-content: flex-start;
background-color: #494949;
color: #3e3e3e;
}

.hcProductBar .icon {
width: 1rem;
height: auto;
}

.hcProductBar .logo {
width: 3rem;
height: auto;
margin-left: -1rem;
}

@media (min-width: 800px) {
.hcProductBar {
position: fixed;
z-index: 999;
top: 0;
left: 0;
width: 4rem;
overflow-y: hidden;
height: 100vh;
flex-direction: column;
align-items: flex-start;
}

.hcProductBar .icon {
width: 1.5rem;
margin-right: 1rem;
}

.hcProductBar nav {
display: flex;
flex-direction: column;
}

.hcProductBar nav > * {
margin-bottom: 2rem;
width: 200px;
color: #3e3e3e;
}

.hcProductBar:hover {
width: 300px;
transition: width .4s;
}

.hcProductBar:hover .logo {
width: 4rem;
margin-left: 0rem;
transition: all .4s;
}

.hcProductBar + .hcContentContainer {
margin-left: 4rem;
width: calc(100% - 4rem);
}
}

@media (max-width: 800px) {
.hcProductBar .hcHeadSpace {
height: auto;
}
}

.hcFacet {
margin-bottom: 1.5rem;
max-width: 250px;
font-size: .85rem;
padding-left: 20px;
position: relative;
}

.dateFrom {
display: block;
}

.sliderImg {
width: 150px !important;
}

.hcFacetSubDivision {
font-size: .90rem;
font-weight: 700;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-right: 20px;
}

.hcFacetItems {
display: flex;
flex-direction: column;
}

.checkBoxItem {
display: inline;
vertical-align: top !important;
}

.hcFacetItem {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.hcFacetTitle {
font-size: .75rem;
font-weight: 700;
display: flex;
flex-direction: row;
justify-content: space-between;
color: rgb(73, 85, 114)
}

.hcFacetCount {
color: #999999;
}

.hcFacetSearch {
display: flex;
justify-content: space-between;
}

.hcFacetSearch input {
border-radius: 4px 0 0 4px;
border-right: 0px;
height: 30px !important;
}

.hcFacetSearch button {
border-radius: 0 4px 4px 0;
}

input[type="checkbox"] {
width: 10px;
height: 10px;
display: inline;
}

.hcSelectedFacet {
font-size: .85rem;
padding: .2rem .5rem;
background-color: #f6f6f6;
border-radius: 3px;
margin: 0 .5rem .5rem 0;
}

.hcSelectedFacet:after {
/*content: url(../images/icons/icon-delete.svg);*/
margin-left: .3rem;
opacity: .4;
}

.hcSelectedFacetType {
color: rgba(0, 0, 0, 0.8);
font-style: italic;
margin-right: .3rem;
}

.hcFacetFilter input {
padding: .3rem;
font-size: .85rem;
height: auto;
border: 1px solid #ebebeb;
}

.hcFacetFilter input::-webkit-input-placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4);
}

.hcFacetFilter input:-ms-input-placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4);
}

.hcFacetFilter input::placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4);
}

.hcIconHelp img {
width: 1.3rem;
height: auto;
cursor: pointer;
}

.hcFacetHelp {
display: inline-block;
background-color: #cccccc;
padding: .5rem;
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
cursor: pointer;
border: 1px solid #999;
border-radius: 4px;
margin: 18px 0;
line-height: normal;
}

.hcFacetHelpVisible {
display: inline-block;
background-color: #cccccc;
padding: .5rem;
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content
}

.hcPagination {
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap;
}

.hcPagination > * {
margin: 0 .5rem;
}

.hcPagination a {
padding: .5rem;
}

.brandName {
font-weight: 700;
}

.brandName img {
height: 50px;
width: auto !important;
}

.chBrandHuc .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #03dbe8 40%, #ddf279 100%) !important;
}

.chBrandHuc .colorBgGrey {
background-color: #edf7f3 !important;
}

.chBrandHuygens .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #082045 40%, #082045 100%) !important;
}

.chBrandHuygens .colorBgGrey {
background-color: #efefef !important;
}

body {
background-color: #fff;
color: #3e3e3e;
}

a {
color: #0087d4;
}

.colorBgGrey {
background-color: #f6f6f6;
}

.colorBgGreyLight {
background-color: #fafafa;
}

.colorBgGreyPlus {
background-color: #dddddd;
}

.hcTxtColorGreyMid {
color: #999999;
}

.bgColorBrand1 {
background-image: none !important;
background-color: #D3D7E2;
}

.bgColorBrand2 {
background-image: none !important;
background-color: #075446;
color: #fff;
}

.bgColorBrand2 a {
color: #fff;
}

.colorBgGrey {
background-color: rgba(7, 84, 70, 0.05) !important;
}

.hcBrandLogo img {
height: 50px;
}

.hcPageHeaderSimple a {
color: #000;
}

.hcGaHeader {
width: 100%;
display: flex;
}

.hcGaHeader > * {
display: flex;
align-items: center;
justify-content: center;
padding: 0 1rem;
}

.hcGaHeaderLogo {
padding-left: 0;
}

.hcGaHeaderLogo img {
width: auto;
height: 65px;
}

.hcGaHeaderToolName {
font-weight: 700;
border-right: 1px solid rgba(0, 0, 0, 0.16);
}

.hcGaTabsLoudness1 a {
color: #3e3e3e;
border-bottom: 3px solid #fff;
margin-left: 1rem;
}

.hcSelected {
font-weight: 700;
border-bottom: 3px solid #075446 !important;
}

.hcBrowseCollection {
border: 1px solid rgb(168, 165, 165);
margin-right: 2.5rem;
margin-bottom: 2rem;
background-color: #e9e9e9;
padding: 4px 8px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.hcTableRow {
display: table-row;
}

.hcTableCell {
padding-right: 24px;
display: table-cell;
}

.hcPassageLeftContainer {
padding: 0 8px 0 0;
border-right: 1px solid #999;
height: 100%;
text-align: center;
}

#hcLeftScan {
width: 250px;
border: 1px solid #666;
cursor: pointer;
}

.hcScanName {
font-size: 0.8rem;
font-weight: bold;
}

.hcPassageAmount {
font-size: 0.95rem;
font-weight: 700;
}

.hcPassageNav {
margin-bottom: 30px;
}

.hcPassageNavImg {
width: 27px !important;
}

.hcManuscriptRow {
display: block;
width: 100%;
}

.hcManuscriptLabel, .hcManuscriptValue, .hcCargoTableLabel, .hcCargoTableValue {
display: table-cell;
width: 100%;
}

.underlineListItem {
display: inline-block;
margin-bottom: 16px;
}

.hcManuscriptImg {
height: 250px !important;
position: absolute;
top: 0;
right: 0;
}

.thumb {
height: 250px;
}

.hcManuscriptSubTable {
width: calc(100% - 250px);
}

.hcManuscriptLabel {
width: 200px !important;
}

.hcManuscriptLabel::after {
content: ":";
}

.hcManuscriptValue {
width: calc(100% - 200px) !important;
}

.hcManuscriptValueSmall {
width: 70%;
display: inline-block;
}

.hcManuscriptValueOtherIDs {
width: 30%;
display: inline-block;
font-size: smaller;
}

.hcManuscriptLabelHeader {
display: block;
width: 100%;
}

.hcManuscriptValueSmall {
font-size: smaller;
}

.hcManuscriptValueOtherIDs {
text-align: right;
font-weight: bold;
color: #005466;
}

.hcManuscriptTable {
width: 100%;
position: relative;
display: table;
margin-bottom: 20px;
}

.hcManuscriptBasicInfo {
width: 100%;
}


.hcCargoTableLabel, .hcCargoTableValue {
padding: 4px 8px;
}

.hcManuscriptLabelHeader {
font-size: larger;
font-weight: 700;
color: #9c2222;
padding-right: 40px;
vertical-align: top;
position: relative;
}

.hcSteinova {
position: absolute;
top: 0;
right: 0;
}

.hcManuscriptLabel {
font-weight: 700;
color: rgb(73, 85, 114);
padding-right: 40px;
vertical-align: top;
}

.hcCargoTable {
display: table;
background-color: #eee;
font-size: 0.95rem;
border: 1px solid #ccc;
margin-bottom: 20px;
}

.hcCargoTableRow {
display: table-row;
}

.hcCargoTableLabel {
font-weight: 700;
color: #333;
}

.hcSumLine {
border-bottom: 1px solid #000;
}

.hcCargoLabel {
background-color: #eee;
border-top: 1px solid #ccc;
border-right: 1px solid #ccc;
border-left: 1px solid #ccc;
}

#mapid {
border: 1px solid #999;
}

.hcClickable {
font-weight: bold;
cursor: pointer;
display: inline;
}

.hcRightMargin {
margin-right: 8px;
display: inline;
}

.hcLetterPicker, .hcPageBrowser {
margin-bottom: 10px;
padding: 4px 8px;
background-color: #dddddd;
}

.hcPageBrowser {
margin-top: 10px;
}

.leaflet-container {
height: 600px;
width: 900px;
margin: 20px auto;
}

.hcFacetGroup {
cursor: pointer;
font-size: 0.95rem;
color: #000;
}

/*.hcRightMargin select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #0087d4;
  border: none;
  margin-left: 0;
  padding-left: 0;
}*/

.hcFacetSelector {
font-size: small;
}

.hcFacetItem {
cursor: pointer;
}

.hcPortPicker {
display: inline-block;
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
height: auto;
margin-left: 10px;
}

.hcPortPicker:focus, select:focus, input:focus {
outline-style: none;
}

.hcFacetValues {
margin-right: 6px;
cursor: pointer;
}

.hcFacetReset {
float: right;
}

.hcFacetLoading {
padding: 6px;
font-style: italic;
}

.hcNumberFound {
font-style: italic;
}

.thumb {
width: 74px;
display: table-cell;
vertical-align: top;
}

.resultCell {
display: block;
position: relative;
padding: 8px;
width: 100%;
}

.resultData {
display: table-cell;
position: relative;
vertical-align: top;
padding-left: 20px;
width: 90%;
}

.resultShelfmark {
display: block;
width: calc(100% - 140px);
font-weight: bold;
color: rgb(73, 85, 114);
cursor: pointer;
}

.psFragment, .psOneVolume, .psFirstVolume, .psIncomplete, .psTwoVolumes, .psDestroyed, .psAddition, .psSecondVolume, .psMarginalia, .psUnknownDesign {
margin-right: 12px;
}

.psSecondVolume {
background-color: #1c5a91;
}

.psFragment {
background-color: #3aa5b0;
}

.psOneVolume {
background-color: #1c5a91;
}

.psFirstVolume {
background-color: #0087d4;
}

.psIncomplete {
background-color: #b88d28;
}

.psTwoVolumes {
background-color: #082045;
}

.psDestroyed {
background-color: #2f5813;
}

.psAddition, .psMarginalia {
background-color: #ab9c1b;
}

.psUnknownDesign {
background-color: #333333;
}



.hcNonCanDesign {
background-color: #9a0c15;
}

.hcCanDesign {
background-color: #1c5a91;
}

.hcUnknownDesign {
background-color: #2f5813;
}

.hcExcDesign {
background-color: #b88d28;
}

.resItem {
margin-bottom: 30px;
padding-left: 8px;
}

.resultDateLocation {
font-style: italic;
font-size: smaller;
}

.colorblock {
display: table-cell;
vertical-align: top;
padding: 0;
}

.square {
display: block;
width: 10px;
height: 10px;
margin-top: 0;
padding: 0;
background-color: brown;
margin-right: 8px;
}

.linkLine {
color: #0087d4;
text-decoration: none;
cursor: pointer;
display: inline-block;
margin-right: 8px;
}



.backLine {
color: rgb(73, 85, 144);
font-weight: bold;
font-size: 10px;
cursor: pointer;
text-align: right;
display: block;
}

.cursLine {
font-style: italic;
}

.formLine {
display: inline;
}

.numSelector {
font-size: 10px !important;
line-height: 12px;
height: 24px;
margin-right: 0;
}

.react-numeric-input input {
width: 40px;
padding: 0;
}

.rangeSelectorBtn {
font-size: 10px;
width: 22px;
height: 22px;
padding: 2px;
margin-left: 6px;
color: #ffffff;
background-color: #1c5a91;
}

.facetAmount {
position: relative;
font-weight: bold;
font-size: 10px;
display: inline;
}

.checkBoxLabel {
display: inline-block;
width: 200px !important;
}

.facetSpacer {
display: inline-block;
}

.hcAmountOfPages {
font-size: 10px !important;
}

.resultMap {
display: block;
}

.leaflet-container {
height: 600px !important;
width: 760px !important;
margin-bottom: 0;
border: 1px solid #000000;
}

.ftSearchBtn {
margin-top: 4px;
padding: 4px;
color: #ffffff;
background-color: #1c5a91;
}

.hcPageNumber {
font-weight: bold;
text-align: right;
color: #666;
font-size: smaller;
margin-top: 10px;
}

.markercluster-map {
height: 90vh;
}

.marker-cluster span {
text-align: center !important;
padding-left: 6px !important;
margin: 0 !important;
}

.mapSwitch {
display: block;
text-align: right;
}

.switchBtn, .activeSwitchBtn {
cursor: pointer;
color: rgb(73, 85, 114);;
font-weight: bold;
font-size: 0.9em;
display: inline-block;
width: 160px;
text-align: center;
margin: 0 10px 30px 0 !important;
border-left: 1px solid #000;
border-bottom: 1px solid #000;
border-right: 1px solid #000;
}

.switchBtn {
background-color: #ccc;
}

.hcBrandLogo {
cursor: pointer;
}

.urlLine {
font-size: 12px;
color: #999;
font-weight: bold;
}

.moveUp {
margin: -30px;
}

.hcManuscriptMaterialType {
color: rgb(73, 85, 114);;
text-decoration: underline;
}

.hcIconHelp {
position: absolute;
  right: 30px;
}

.hcIconHelpDetail{
height: 20px !important;
width:  20px !important;

}

.detailHelp{
display: inline-block;
cursor: pointer;
}

.hcIconStyle{
height: 20px;
width: auto;
}

.iiif{
width: 40px;
height: 40px;
cursor: pointer;
}

.imgLink{
cursor: pointer;
}




html,
body {
padding: 0;
margin: 0; }

* {
box-sizing: border-box; }

ul, ol {
padding: 0;
margin: 0; }

li {
padding: 0;
margin: 0 0 0 1rem; }

a {
text-decoration: none; }

ul ul {
margin: 0 0 0 1em; }

ul ul ul {
margin: 0 0 0 2em; }

.hcClrBg_Info {
background-color: #1f96ad; }

.hcClrBg_InfoLight {
background-color: #ebf7fa; }

.hcClrBg_Reward {
background-color: #9fcf3f; }

.hcClrBg_RewardLight {
background-color: #d6ebad; }

.hcClrBg_Error {
background-color: #de6e6e; }

.hcClrBg_ErrorLight {
background-color: #ffaa99; }

.hcClrBg_Caution {
background-color: #e2c765; }

.hcClrBg_CautionLight {
background-color: #efe0a9; }

.hcTxt_Caution {
color: #c54a11; }

.hcClrBg_Grey05 {
background-color: #f2f2f2; }

.hcClrBg_Grey10 {
background-color: #e6e6e6; }

.hcClrBg_Grey15 {
background-color: #d9d9d9; }

.hcClrBg_Grey20 {
background-color: #cccccc; }

.hcClrBg_Grey30 {
background-color: #b3b3b3; }

.hcClrBg_Grey70 {
background-color: #4d4d4d; }

.hcClrBg_Grey80 {
background-color: #333333; }

.hcClrBg_Grey90 {
background-color: #1a1a1a; }

.hcClrTxt {
color: #1a1a1a; }

.hcClrTxt_Grey {
color: gray; }

.hcClrTxt_Dark {
color: #000; }

body {
background-color: #fff;
color: #3e3e3e; }

a {
color: #0087d4; }

.colorBgGrey {
background-color: #f6f6f6; }

.colorBgGreyLight {
background-color: #fafafa; }

.colorBgGreyPlus {
background-color: #dddddd; }

.hcTxtColorGreyMid {
color: #999999; }

body {
font-family: 'Roboto', sans-serif;
font-weight: 300;
font-size: 18px;
line-height: 140%;
font-style: normal;
color: #1a1a1a; }

h1 {
font-size: 1.5em;
line-height: 130%;
font-weight: 700; }

h2 {
font-size: 1.3em;
line-height: 130%;
font-weight: 700; }

h3 {
font-size: 1em;
line-height: 130%;
font-weight: 700; }

h4 {
font-size: .85em;
line-height: 130%;
font-weight: 300;
font-style: italic; }

@media (min-width: 600px) {
body {
font-size: 18px;
line-height: 150%; }
h1 {
font-size: 2em; }
h1, h2, h3, h4 {
line-height: 150%; } }

h1, h2, h3, h4 {
margin-top: 0;
margin-bottom: .3em; }

p {
margin-top: 0;
margin-bottom: 1em; }

strong {
font-weight: 700; }

.hcSmallTxt {
font-size: .85rem; }

.hcMonospace {
font-family: monospace; }

.hcNotBold {
font-weight: 300; }

.icon {
width: 1.3rem !important;
height: auto !important; }

.hcIconText {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start; }
.hcIconText .icon {
margin-right: .5rem; }

.hcIcon svg {
display: block;
width: 2rem;
height: auto; }

.hcIconFill {
fill: #3e3e3e; }

.hcIconStroke {
stroke: #3e3e3e;
stroke-width: 3px; }

.hcIconSmall svg {
width: 1rem; }

.hcBasicSideMargin {
min-width: 335px;
padding-left: 2rem;
padding-right: 2rem; }

.hcBasicPading {
padding: 1rem; }

.hcMarginBottom05 {
margin-bottom: .5rem; }

.hcMarginBottom1 {
margin-bottom: 1rem; }

.hcMarginBottom1_5 {
margin-bottom: 1.5rem; }

.hcMarginBottom2 {
margin-bottom: 2rem; }

.hcMarginBottom3 {
margin-bottom: 3rem; }

.hcMarginBottom4 {
margin-bottom: 4rem; }

.hcMarginBottom5 {
margin-bottom: 5rem; }

.hcMarginBottom15 {
margin-bottom: 15rem; }

.hcMarginTop05 {
margin-top: .5rem; }

.hcMarginTop1 {
margin-top: 1rem; }

.hcMarginTop2 {
margin-top: 2rem; }

.hcMarginTop3 {
margin-top: 3rem; }

.hcMarginTop4 {
margin-top: 4rem; }

.hcMarginTop5 {
margin-top: 5rem; }

.hcMarginBackground {
padding-top: 4rem;
padding-bottom: 4rem; }

.hcNestedItemMargin1 > * {
margin-right: 1rem;
margin-bottom: 1rem; }

.hcMarginLeft05 {
margin-left: .5rem; }

.hcMarginLeft1 {
margin-left: 1rem; }

.hcMarginLeft2 {
margin-left: 2rem; }

.hcMarginLeft3 {
margin-left: 3rem; }

.hcMarginRight05 {
margin-right: .5rem; }

.hcMarginRight1 {
margin-right: 1rem; }

.hcMarginRight2 {
margin-right: 2rem; }

.hcMarginRight3 {
margin-right: 3rem; }

.hcAlignHorizontal {
display: flex;
flex-direction: row; }

.hcAlignVertical {
display: flex;
flex-direction: column; }

.hcAlignLeft {
display: flex;
justify-content: flex-start; }

.hcAlignRight {
display: flex;
justify-content: flex-end; }

.tikkieKleiner {
font-size: 15px !important;
}

.hcAlignVertical.alignRight {
display: flex;
align-items: flex-end; }

.hcAlignLeftRight {
display: flex;
justify-content: space-between; }

.hcAlignCenter {
display: flex;
justify-content: center; }

.hcAlignVertical.alignCenter {
display: flex;
align-items: center; }

.hcRowLeft {
display: flex;
justify-content: flex-start;
align-items: center; }

.hcRowLeftTop {
display: flex;
justify-content: flex-start;
align-items: flex-start; }

.hcRowRight {
display: flex;
justify-content: flex-end;
align-items: center; }

.hcRowRightTop {
display: flex;
justify-content: flex-end;
align-items: flex-start; }

.hcRowJustify {
display: flex;
justify-content: space-between;
align-items: center; }

.hcRowJustifyTop {
display: flex;
justify-content: space-between;
align-items: flex-start; }

.hcTxtLeft {
text-align: left; }

.hcTxtRight {
text-align: right; }

.hcTxtCenter {
text-align: center; }

.hcList {
display: flex;
flex-direction: column; }
.hcList li {
margin: .5rem 0 .5rem 0; }

.hcList > * {
display: flex;
flex-direction: column;
justify-content: space-between;
border-top: 1px solid #d9d9d9;
padding-top: .7rem;
padding-bottom: .7rem; }

.hcList > *:hover {
background-color: #d9d9d9;
margin: 0 -1rem;
padding-left: 1rem;
padding-right: 1rem; }

.hcListDisctinctLines > * {
border-top: none; }

.hcListDisctinctLines > *:nth-child(odd) {
background-color: #f2f2f2;
margin: 0 -1rem;
padding-left: 1rem;
padding-right: 1rem; }

.hcListDisctinctLines > *:nth-child(odd):hover {
background-color: #d9d9d9; }

.hcListDisctinctLines > *:nth-child(even) {
background-color: none; }

.hcList > * > * {
flex: 1 1;
margin-right: 1rem; }

@media (min-width: 600px) {
.hcList > * {
flex-direction: row;
display: flex; } }

.hcListItemLong {
flex: 2 1; }

.hcListHeader {
background: none;
border: none; }

.hcListHeader:hover {
background: none;
border: none; }

.hcListBasicResult {
cursor: pointer; }

.hcRowCard {
background-color: #fff;
padding: 1rem;
margin: 0 -1rem .5rem -1rem;
border-radius: 2px; }

.hcRowCard > * {
display: flex;
flex-direction: column;
justify-content: flex-start; }

.hcListItemBlock {
padding: 0;
transition: background-color .3s;
margin-bottom: 1.5rem; }

.hcListItemBlock > * {
padding: 1rem 2rem; }

.hcListItemDPPBlock {
flex: 2 1;
display: flex;
flex-direction: column;
justify-content: space-between; }

.hcKwic {
border-bottom: 3px dotted #e7da67; }

.hcListItemThumb {
overflow-y: scroll;
display: flex;
align-items: flex-start;
justify-content: center;
background-color: #3b3b3b;
padding-top: .5rem; }

.hcListItemThumb div {
height: 170px; }

.hcListItemThumb img {
width: 200px !important;
height: auto !important; }

.hcResultsHeader {
display: flex;
justify-content: space-between;
}

.manFoundHeader{
vertical-align: center;
padding:0;
}

.hcMessageBar {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
font-size: .85rem;
line-height: 140%;
font-style: italic;
background-color: whitesmoke;
border-radius: 4px; }

.hcMessageBar > * {
padding: .5rem; }

.notificationInform {
color: #005466;
background-color: #ccf6ff; }
.notificationInform .hcIconFill {
fill: #005466; }
.notificationInform .hcIconStroke {
stroke: #005466; }

.notificationCaution {
color: #967d1c;
background-color: white; }
.notificationCaution .hcIconFill {
fill: #967d1c; }
.notificationCaution .hcIconStroke {
stroke: #967d1c; }

.notificationError {
color: #3b0b00;
background-color: #ffb2a1; }
.notificationError .hcIconFill {
fill: #3b0b00; }
.notificationError .hcIconStroke {
stroke: #3b0b00; }

.notificationReward {
color: #476018;
background-color: #f1f8e5; }
.notificationReward .hcIconFill {
fill: #476018; }
.notificationReward .hcIconStroke {
stroke: #476018; }

.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
display: flex;
flex-direction: column; }

.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns > * {
flex-basis: 100%; }

@media (min-width: 800px) {
.hc2columns, .hc3columns, .hc4columns, .hc5columns, .hc6columns {
flex-direction: row; }
.hc2columns > * {
flex-basis: 50%; }
.hc3columns > * {
flex-basis: 33%; }
.hc4columns > * {
flex-basis: 25%; }
.hc5columns > * {
flex-basis: 20%; }
.hc6columns > * {
flex-basis: 15%; } }

button {
font-size: .7rem;
padding: .5rem;
border-radius: 4px;
background-color: rgba(0, 0, 0, 0.07);
border: 1px solid rgba(0, 0, 0, 0.09);
cursor: pointer;
font-weight: 700;
margin: 0 .3rem;
max-height: 40px; }

.hc-loud {
padding: .8rem;
font-size: 1rem; }

.hc-small {
padding: .3rem;
font-size: .6rem; }

.hc-subtle {
background: none;
border: none;
box-shadow: none;
color: #0087d4; }

.hcfixedSideButton {
width: 2rem;
padding: .2rem;
border-radius: 0 4px 4px 0; }

.hcToggle button {
margin: 0 0rem;
border-radius: 0px;
border-right: 0px; }

.hcToggle > *:first-child {
border-radius: 4px 0 0 4px; }

.hcToggle > *:last-child {
border-radius: 0 4px 4px 0;
border-right: 1px solid rgba(0, 0, 0, 0.09); }

.buttonRemove:before {
content: '';
width: 20px;
height: 20px;
display: block; }

input, select, option, textarea {
width: 70%;
padding: .5rem;
border: 1px solid #e1ebf3;
border-radius: 4px;
font-size: 1rem;
font-weight: 300;
color: #3e3e3e;
height: 40px; }

.hcForm input, .hcForm select, .hcForm option, .hcForm textarea {
background-color: #f0f5f9;
max-width: 480px;
background-color: #f0f5f9; }

.hcForm input[type="checkbox"] {
background-color: #f0f5f9;
max-width: 30px;
background-color: #f0f5f9; }

.hcForm textarea {
height: auto;
min-height: 150px; }

input::-webkit-input-placeholder {
color: gray;
font-style: italic;
font-size: .85rem; }

input:-ms-input-placeholder {
color: gray;
font-style: italic;
font-size: .85rem; }

input::placeholder {
color: gray;
font-style: italic;
font-size: .85rem; }

.hcLabel {
color: #999999;
text-transform: uppercase;
font-size: .85rem; }

.hcList2 {
margin-left: 0;
margin-right: 0;
padding-left: 0;
padding-right: 0; }
.hcList2 li {
margin-left: 0;
margin-right: 0;
padding-left: 0;
padding-right: 0; }

.hcList2NoIcon {
list-style: none; }

.hcStickyFooter {
position: fixed;
width: 100%;
min-width: 40px;
bottom: 0; }

.hcStickyFooterLook {
background-color: #fff;
padding-top: 1rem;
padding-bottom: 1rem;
border-top: 1px solid #d9d9d9; }

.hcTextSnippit::before {
content: " ..."; }

.hcTextSnippit::after {
content: "... "; }

.hcDatasetEntity {
font-size: .85rem;
padding: .0rem 0 .0rem .5rem;
border: 1px solid #e7e7e7;
border-radius: .5rem 0 0 .5rem;
margin: 0 .5rem .5rem 0;
display: flex;
flex-direction: row;
justify-content: space-between; }

.hcDatasetEntityAmount {
border-radius: 3px;
padding: 0 .5rem;
margin: -1px -2px -1px 0;
background-color: #e7e7e7;
color: #3e3e3e;
min-width: 3.5rem;
text-align: right; }

.hcPartnerLogo img {
width: 100px !important;
height: auto !important; }

.hcDensity {
width: 100%;
min-width: 100px;
background-color: #e3e3e3; }

.hcDensityBar {
background-color: #3aa5b0;
font-size: .5rem;
color: #fff;
padding: 0 0 0 .5rem; }

.hcUriType {
font-family: monospace;
font-size: .85rem; }

.hcBreakAll {
overflow-wrap: break-word;
word-break: break-all;
-webkit-hyphens: auto;
    -ms-hyphens: auto;
        hyphens: auto; }

.hcBarDataset {
display: flex;
flex-direction: column;
padding-bottom: 1rem; }

@media (min-width: 600px) {
.hcBarDataset {
flex-direction: row; }
span {
display: flex;
flex-direction: row;
align-items: baseline;
padding-right: 1rem; }
select {
background-color: none; } }

.hcBorderBottom {
border-bottom: 1px solid #ededed; }

.hcMapPlaceholder {
width: 100%;
height: 250px;
background-size: cover;
background-position: center; }

hr {
border: none;
height: 1px;
color: rgba(0, 0, 0, 0.15);
/* old IE */
background-color: rgba(0, 0, 0, 0.15);
/* Modern Browsers */ }

.hcStickOutBox {
margin: -1rem;
padding: 1rem;
background-color: #f2f2f2; }

.hcStickOutBoxAside {
margin: 0 -1rem;
padding: 0 1rem;
background-color: #f2f2f2; }

.hcRoundedCorners {
border-radius: 4px; }

.hcRoundedCornersTop {
border-radius: 4px 4px 0 0; }

.hcTextTruncate {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 200px; }

.hcContentContainer {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%; }

.hcContentContainer > * {
width: 100%;
max-width: 1200px; }

.hcBlockText {
width: 100%; }
.hcBlockText ol, .hcBlockText ul {
margin-left: 1rem; }

.hcBlockText > * {
width: 100%; }

@media (min-width: 800px) {
.hcBlockText > * {
max-width: 70%; } }

.hcHeadSpace {
height: 25vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start; }

@media (min-width: 800px) {
.hcHeadSpace {
flex-direction: row;
align-items: center;
justify-content: space-between; } }

.hcBlockScroll {
height: 100vh;
overflow-y: auto; }

.hcBlockHeroWithTeaser {
height: 66vh;
display: flex;
justify-content: center; }

.hcLayoutBasicMainAside, .hcLayoutBasicMainFixedAside {
display: flex;
flex-direction: column;
align-items: flex-start; }

.hcLayoutAside {
width: 100%; }

.hcLayoutMain {
width: 100%; }

@media (min-width: 800px) {
.hcLayoutBasicMainAside {
flex-direction: row; }
.hcLayoutAside {
width: 300px; }
.hcLayoutMain {
width: calc(100vw - 300px);
min-width: calc(800px - 300px); } }

@media (min-width: 800px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end; }
.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 300px;
position: fixed !important;
top: 0;
left: 0;
bottom: 0; }
.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 300px);
margin-left: 300px; }
.hcLayoutBasicMainFixedAside .asideWithProductBar {
display: flex; }
.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem; }
.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 300px); } }

@media (min-width: 1300px) {
.hcLayoutBasicMainFixedAside {
flex-direction: row;
justify-content: flex-end; }
.hcLayoutBasicMainFixedAside .hcLayoutAside {
width: 400px; }
.hcLayoutBasicMainFixedAside .hcLayoutMain {
width: calc(100vw - 400px);
margin-left: 400px; }
.hcLayoutBasicMainFixedAside .asideWithProductBar > *:last-child {
margin-left: 4rem;
width: 100%; }
.hcLayoutBasicMainFixedAside .hcStickyFooter {
width: calc(100vw - 400px); } }

.hcLayoutFacet-Result {
display: flex;
flex-direction: column;
align-items: flex-start; }

.hcLayoutFacets {
width: 100%; }

.hcLayoutResults {
width: 100%; }

.hcLayoutFacetsToggel {
display: flex;
height: 230px;
flex-direction: row;
overflow-x: auto;
background-color: #fafafa; }
.hcLayoutFacetsToggel div {
width: 250px;
margin-right: 3rem; }

#showFacets {
position: fixed;
top: 45vh;
left: 0; }

@media (min-width: 800px) {
.hcLayoutFacet-Result {
flex-direction: row; }
.hcLayoutFacets {
width: 350px; }
.hcLayoutResults {
width: calc(100% - 350px); }
.hcLayoutFacetsToggel {
display: block;
overflow-x: none;
height: auto;
flex-direction: column;
background: none; }
.hcLayoutFacetsToggel div {
width: 100%;
margin-right: auto; }
#showFacets {
display: none;
position: fixed; } }

.hcThumbsRow {
display: flex;
flex-direction: row; }

.hcThumbsRow img {
margin: 1rem 0 1rem 1rem;
cursor: pointer; }

.hclayoutTextImageDivider {
display: flex;
flex-direction: row;
height: calc(100vh - 150px); }

.hcViewer {
width: 45%; }

.hcMeta {
max-width: 250px; }

.hcText {
display: flex;
flex-direction: row;
justify-content: center; }

.hcText > * {
max-width: 550px;
margin-bottom: 500px; }

.hcLayout-right-sidebar {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%; }

.hcLayout-right-sidebar-main {
width: 100%; }

.hcLayout-right-sidebar-aside {
width: 100%; }

@media (min-width: 800px) {
.hcLayout-right-sidebar {
flex-direction: row; }
.hcLayout-right-sidebar-main {
width: calc(100% - 250px - 2rem); }
.hcLayout-right-sidebar-aside {
width: 250px; } }

.hcSidebar div {
margin-bottom: 1.5rem; }

.hcSpitscreen {
display: flex;
flex-direction: column; }

.hcSpitscreen > * {
display: flex;
flex-direction: column;
justify-content: flex-start; }

@media (min-width: 1400px) {
.hcSpitscreen {
flex-direction: row; } }

.hcLayoutWideCols {
display: flex;
flex-direction: column;
width: 100%; }
.hcLayoutWideCols > * {
width: 100%;
max-width: 50vw; }

@media (min-width: 800px) {
.hcLayoutWideCols {
flex-direction: row; }
.hcWideColsFixedWidth {
width: 100%;
max-width: 25vw; } }

.hcLayoutWideColsInner {
width: 100%; }

.navigation a {
color: #3e3e3e;
font-weight: 700;
font-size: 14px;
padding: .2rem;
margin-right: .5rem; }

.navigation .navigation-sub > * {
font-weight: 300; }

.navigation li {
list-style: none; }

.navigation .navigation-secundairy-items {
display: none; }

.hcPageHeaderSimple {
display: flex;
flex-direction: column;
justify-content: space-between;
font-size: .9rem;
width: 100%; }
.hcPageHeaderSimple nav {
display: flex;
flex-direction: row;
height: 100%;
align-items: baseline;
line-height: 130%; }
.hcPageHeaderSimple nav a {
height: 100%;
padding: 1.5rem 1rem;
display: flex;
align-items: center; }
.hcPageHeaderSimple nav a:hover {
background-color: rgba(255, 255, 255, 0.11); }
.hcPageHeaderSimple nav a:first-child {
margin-left: -1rem; }
.hcPageHeaderSimple .hcMenuButton {
border: none;
background: none;
font-size: .9rem;
color: #fff;
display: flex;
flex-direction: row;
align-items: center;
margin-left: 2rem; }
.hcPageHeaderSimple .hcMenuButton img {
width: .7rem;
height: .7rem;
margin-left: .5rem; }

.hcPageHeaderSimple > * {
height: 100%;
display: flex;
align-items: center; }

.hcSecundairNav {
display: none; }

@media (min-width: 600px) {
.hcPageHeaderSimple {
flex-direction: row; }
.hcSecundairNav {
display: flex; }
.hcMenuButton {
display: none; } }

.hcBrand {
display: flex;
flex-direction: row;
justify-content: flex-start;
height: 100%; }

.hcBrandLogo img {
height: 33px;
width: auto; }

.hcBrandName {
padding: 1.5rem 1rem 1rem 1rem;
font-weight: 700; }

@media (min-width: 600px) {
.hcBrandLogo img {
height: 60px;
width: auto; } }

.hcFullscreenMenu {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
font-size: 1.5rem !important;
align-items: flex-start;
justify-content: flex-start; }
.hcFullscreenMenu nav {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
min-height: 50vh; }
.hcFullscreenMenu nav a {
padding: 0 0rem; }
.hcFullscreenMenu #hcMenuButton {
position: fixed;
top: 30px;
right: 30px; }
.hcFullscreenMenu .hcSecundairNav {
display: flex; }
.hcFullscreenMenu nav a:first-child {
margin-left: 0rem; }
.hcFullscreenMenu .hcBrand {
margin-left: 0rem;
margin-top: 6rem;
margin-bottom: 3rem; }
.hcFullscreenMenu .hcPageHeaderSimple {
flex-direction: column; }

.hcTabs {
display: flex;
width: 100%; }
.hcTabs .hcSelectedTab {
font-weight: 700;
background-color: #f2f2f2; }

.hcTabsVert {
flex-direction: column; }
.hcTabsVert .hcTabLabels {
display: flex;
flex-direction: column; }

.hcTabsHoriz {
flex-direction: column; }
.hcTabsHoriz .hcTabLabels {
display: flex;
flex-direction: row; }

.hcTabLabels {
width: 100%;
position: relative;
z-index: 100; }

.hcTabAllContent {
width: 100%;
position: relative;
z-index: 10;
background-image: linear-gradient(#f2f2f2, #fff 50px); }

@media (min-width: 500px) {
.hcTabsVert {
flex-direction: row; }
.hcTabsVert .hcTabLabels {
width: 20%; }
.hcTabsVert .hcTabAllContent {
width: 80%; } }

.hcTabContent {
width: 100%; }

.hcTabLabel {
cursor: pointer;
padding: .5rem 1rem;
display: flex;
flex-direction: row;
align-items: center; }

.hcFormItem {
display: flex;
flex-direction: column;
width: 100%; }

.hcFormItem > * {
width: 100%; }

.hcFeedbackError {
border-bottom: 3px solid #d42600 !important; }

.hcFeedbackSucces {
border-bottom: 3px solid #d42600 !important; }

.hcFormItemRow {
display: flex;
flex-direction: column;
justify-content: space-between; }

.hcFormItemExtra {
width: 100%; }

@media (min-width: 1280px) {
.hcFormItemRow {
flex-direction: row; }
.hcFormItemExtra {
width: 60%;
display: flex;
align-items: center;
padding-left: 1rem; } }

.hcProvenanceComp {
width: 100%;
display: flex;
flex-direction: column;
display: none; }
.hcProvenanceComp textarea {
height: 30px !important; }

.hcFormStack {
display: flex;
flex-direction: column;
border-top: 1px solid #e8e8e8;
padding-top: 2rem;
width: 100%; }

.hcFormStack > * {
width: 100%;
display: flex;
flex-direction: column; }

.hcStackInfo {
margin-bottom: 2rem;
margin-right: 2rem; }

@media (min-width: 800px) {
.hcFormStack {
flex-direction: row; }
.hcStackInfo {
width: calc(30% - 2rem); }
.hcStackFormItems {
width: calc(70%);
padding-top: 1.5rem; } }

.hcProductBar {
width: 100vw;
padding: 0 1.5rem;
display: flex;
justify-content: flex-start;
background-color: #494949;
color: #3e3e3e; }
.hcProductBar .icon {
width: 1rem;
height: auto; }
.hcProductBar .logo {
width: 3rem;
height: auto;
margin-left: -1rem; }

@media (min-width: 800px) {
.hcProductBar {
position: fixed;
z-index: 999;
top: 0;
left: 0;
width: 4rem;
overflow-y: hidden;
height: 100vh;
flex-direction: column;
align-items: flex-start; }
.hcProductBar .icon {
width: 1.5rem;
margin-right: 1rem; }
.hcProductBar nav {
display: flex;
flex-direction: column; }
.hcProductBar nav > * {
margin-bottom: 2rem;
width: 200px;
color: #3e3e3e; }
.hcProductBar:hover {
width: 300px;
transition: width .4s; }
.hcProductBar:hover .logo {
width: 4rem;
margin-left: 0rem;
transition: all .4s; }
.hcProductBar + .hcContentContainer {
margin-left: 4rem;
width: calc(100% - 4rem); } }

@media (max-width: 800px) {
.hcProductBar .hcHeadSpace {
height: auto; } }

.hcFacet {
margin-bottom: 3rem;
max-width: 250px;
font-size: .85rem; }

.hcFacetItems {
display: flex;
flex-direction: column; }

.hcFacetItem {
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center; }
.hcFacetItem .hcFacetItemSpan {
display: flex;
flex-direction: row;
align-items: center;
height: 15px; }
.hcFacetItem .hcFacetItemSpan input[type='checkbox'] {
width: 15px;
background-color: #eee090; }

.hcFacetItemSelected {
font-weight: 700; }

.hcFacetTitle {
text-transform: uppercase;
font-size: .85rem;
font-weight: 700;
display: flex;
flex-direction: row;
justify-content: space-between; }

.hcFacetCount {
color: #999999; }

.hcFacetSearch {
display: flex;
justify-content: space-between; }
.hcFacetSearch input {
border-radius: 4px 0 0 4px;
border-right: 0px; }
.hcFacetSearch button {
border-radius: 0 4px 4px 0; }

.hcSelectedFacet {
font-size: .85rem;
padding: .2rem .5rem;
background-color: #f6f6f6;
border-radius: 3px;
margin: 0 .5rem .5rem 0;
cursor: pointer; }

.hcSelectedFacet:after {
content: url(/static/media/icon-delete.09acc753.svg);
margin-left: .3rem;
opacity: .4; }

.hcSelectedFacetType {
color: rgba(0, 0, 0, 0.4);
font-style: italic;
margin-right: .3rem; }

.hcFacetFilter input {
padding: .3rem;
font-size: .85rem;
height: auto;
border: 1px solid #ebebeb; }

.hcFacetFilter input::-webkit-input-placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4); }

.hcFacetFilter input:-ms-input-placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4); }

.hcFacetFilter input::placeholder {
font-style: italic;
color: rgba(0, 0, 0, 0.4); }





.hcPagination {
display: flex;
flex-direction: row;
justify-content: center;
flex-wrap: wrap; }

.hcPagination > * {
margin: 0 .5rem; }

.hcPagination a {
padding: .5rem; }

.hcModal {
background-color: rgba(0, 0, 0, 0.85);
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 10000; }

.hcModelContentWrap {
background-color: #ffffff;
height: calc(100vh - 6rem);
width: calc(100vw - 4rem);
margin: 6rem 0;
overflow-y: scroll; }

.hcQueryBuilder {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
font-size: 1.1rem;
font-weight: 700; }

.hcQBuildBlock {
padding: .2rem .3rem;
display: flex;
align-items: center;
margin-left: -1px;
height: 2.5rem; }
.hcQBuildBlock .hcIconFill {
fill: #b3b3b3 !important; }

.hcQBuildBlock:hover {
box-shadow: 2px 2px 2px #d9d9d9; }

.hcQBuildBlockInt {
border: 1px solid #cccccc;
cursor: pointer; }

.hcQBuildBlockChoice {
display: flex;
flex-direction: column;
margin-bottom: -7.5rem; }

.hcQBuildBlockChoice > * {
border-top: 1px solid #cccccc;
border-left: 1px solid #cccccc;
border-right: 1px solid #cccccc;
cursor: pointer;
padding: .2rem .3rem;
width: 8rem; }

.hcQBuildBlockChoice > *:last-child {
border-bottom: 1px solid #cccccc;
border-radius: 0 0 4px 4px; }

.hcQBuildBlockChoice > *:first-child {
border-radius: 4px 4px 0 0; }

.hcQBuildBlockBrowse {
background-color: #e6e6e6;
height: 50vh;
margin-bottom: calc(2.5rem - 50vh);
z-index: 200;
padding: .2rem .3rem;
width: 20rem;
display: flex;
flex-direction: column;
justify-content: flex-start;
box-shadow: 2px 2px 2px #d9d9d9; }
.hcQBuildBlockBrowse input {
font-size: .85rem;
border: 1px solid #cccccc; }
.hcQBuildBlockBrowse .hcList {
font-weight: 300;
font-size: .85rem;
flex: 1 1;
overflow-y: auto;
width: 100%; }
.hcQBuildBlockBrowse .hcList > * {
margin: 0;
padding-left: 1rem; }

.hcCodeEditor {
display: flex;
flex-direction: row; }
.hcCodeEditor textarea {
border: none;
padding: 0;
min-height: 40vh; }

.hccodeTxt {
font-family: "Lucida Console", Monaco, monospace;
font-weight: 300;
font-size: .85rem;
line-height: 1.5rem; }

.hcLineNumbers {
display: flex;
flex-direction: column;
text-align: right;
font-size: .7rem; }
.hcLineNumbers > * {
text-align: right;
display: block; }

.hcLogicBox {
width: 100%; }

.hcLogicBox-Row {
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: .5rem;
margin-left: -1.5rem;
padding: .5rem; }

.hcLogicBox-RowEditDelete {
display: flex;
flex-direction: row;
justify-content: flex-end; }
.hcLogicBox-RowEditDelete > * {
margin-right: 1rem; }

.hcLogicBox-group {
margin-bottom: 1rem;
border-left: 5px solid gray;
padding-left: 2rem; }

.hcLogicBox-group-operator {
font-weight: 700;
margin-bottom: 1rem;
margin-left: -.5rem;
color: gray; }

.hcTree {
margin: 0;
padding: 0; }
.hcTree li {
list-style: none;
margin-top: 0;
margin-left: 0;
margin-right: 0;
padding: 0;
display: flex;
flex-direction: column; }
.hcTree li ul {
margin: 0; }
.hcTree li ul li {
margin-top: 0;
margin-right: 0;
margin-left: 2rem; }
.hcTree li:hover {
font-weight: 700; }
.hcTree li:hover ul {
font-weight: 300; }

.hcTreeIcon {
width: 25px;
height: auto; }

.hcTreeListGroup {
display: flex;
flex-direction: row; }

.hcMirador {
width: 100%;
height: 100%; }
.hcMirador img {
width: 100%;
height: auto; }

.brandName {
font-weight: 700; }

.brandName img {
height: 50px;
width: auto !important; }

.chBrandHuc .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #03dbe8 40%, #ddf279 100%) !important; }

.chBrandHuc .colorBgGrey {
background-color: #edf7f3 !important; }

.chBrandHuygens .bgColorBrand1 {
background-image: -webkit-linear-gradient(25deg, #082045 40%, #082045 100%) !important; }

.chBrandHuygens .colorBgGrey {
background-color: #efefef !important; }

body {
background-color: #fff;
color: #3e3e3e; }

a {
color: #0087d4; }

.colorBgGrey {
background-color: #f6f6f6; }

.colorBgGreyLight {
background-color: #fafafa; }

.colorBgGreyPlus {
background-color: #dddddd; }

.hcTxtColorGreyMid {
color: #999999; }

.bgColorBrand1 {
background-color: #082045;
color: #fff; }

.bgColorBrand1 a {
color: #fff; }

.colorBgGrey {
background-color: #efefef !important; }

.hcFeedbackError {
border-bottom: 3px solid #d06f5d !important; }

.hcFeedbackSucces {
border-bottom: 3px solid #64a264 !important; }

.hcFormDiv {
border: 2px solid #efefef;
padding: 2rem 1rem; }

hr {
color: #dee6ed;
background-color: #dee6ed; }

.bgColorBrandIK {
background-color: #0A3D73; }
.bgColorBrandIK a {
color: #fff; }

.hcIKBgLight {
background-color: #f8f4f1; }

.hcSmallTxt {
font-size: .9rem; }

.hcDataLabel {
font-size: .9rem;
font-weight: 700;
margin-bottom: -.3rem; }

.hcIKkImageBlock {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: #f8f4f1;
padding: 1rem; }
.hcIKkImageBlock img {
max-width: 500px; }

.hcIKheaderSplit {
display: flex;
flex-direction: column; }

@media (min-width: 500px) {
.hcIKheaderSplit {
flex-direction: row; } }

.hcIKresultCard {
display: flex;
flex-direction: column;
margin: -1rem;
padding: 1rem; }

.hcIKresultCardInfo {
display: flex;
flex-direction: column; }

@media (min-width: 500px) {
.hcIKresultCardInfo {
flex-direction: row; } }

.hcIKkImageBlockResult {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
background-color: #f8f4f1;
padding: .5rem; }
.hcIKkImageBlockResult img {
width: 100px;
height: auto; }

.hcIKstate {
display: flex;
width: 100%;
justify-content: flex-end;
align-items: center; }

.hcIKstateLabelBlock {
padding-right: .5rem;
text-align: right; }

.hcIKstateIconBlock {
padding: .2rem .2rem .2rem .2rem;
display: flex;
align-items: center;
justify-content: center; }

.hcIKstateIconBlock img {
height: 20px;
width: auto; }

.hcIKselectedFacet {
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
width: 100%;
white-space: nowrap;
border-bottom: 1px solid #e6e6e6;
font-size: .9rem; }

.hcIKselectedFacetBuffer {
width: 100%;
display: flex;
flex-direction: row;
flex-wrap: wrap; }

.hcIKselectedFacetItem {
display: flex;
flex-direction: row;
align-items: center;
margin-right: 1rem;
margin-bottom: .3rem; }

.hcIKselectedFacetVal {
padding: 0rem .3rem;
background-color: #e4e4e4;
border-radius: 3px;
margin-left: .5rem;
display: flex;
flex-direction: row;
align-items: center; }
.hcIKselectedFacetVal .hcIcon {
padding: 0 0 0 .3rem;
opacity: .7; }

.hcIKidBlock {
width: 70px;
text-align: right; }

.hcBrandLogo img {
width: 300px;
height: auto;
margin-top: 1.2rem; }

.navMenuItem{
font-weight: bold;
}

.clickableResultItem {
cursor: pointer;
}

.manuscriptSelector {
width: 200px;
}

.sliderBox {
display: block;
width: 160px !important;
margin: 10px 0;
}

.fbMessage{
display: inline-block;
background-color: #eedddd;
margin: 20px 0;
font-size: small;
width: 180px;
line-height: normal;
border: 1px solid #999;
padding: 8px;
}

.rangeLabelFrom, .rangeLabelTo {
font-weight: bold;
color: #5d5d5d;
display: inline;
padding-right: 2px;
}

.rangeLabelTo {
padding-left: 4px;
}

.hcBookSelector {
margin-bottom: 12px;
}

.bookLine{
width: 80%;
margin-left: -12px;
margin-top: 16px;
}

.downloadFormat{
display: inline;
cursor: pointer;
color: #0a3d73;
font-weight: bold;
}

.downloadFormats{
font-size: smaller;
display: block;
margin-bottom: 30px;
margin-top: -20px;
text-align: right;
}

.innovationListItem, .relationList {
margin-bottom: 10px;
font-size: smaller;
}

.other_url_label {
display: inline-block;
margin-left: 8px;
}

.facetSubHeader {
display: inline-block;
margin-left: 20px;
font-weight: bold;
font-size: smaller;
width: 300px !important;
}

.additional_content_link {
display: inline-block;
color: #0087d4;
cursor: pointer;
margin-left: 6px;
}

.additional_content_link:before {
content: "| ";
color: #000000;
}




